import React, { useState } from 'react';



export default function Gallery(props) {
  const [current, setCurrent] = useState(0);
  const length = props.slides.length;

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  const currentSlide = (x) => {
    setCurrent(x);
  }

  if (!Array.isArray(props.slides) || props.slides.length <= 0) {
    return null;
  }



  return (
    <div className='Gallery-container'>
      <div className="Gallery">
        <div className='container'>
          <div className="primarySlide">
            <div className='fit'>
              <img src={props.slides[current].image} />
            </div>
          </div>
          <a className="prev" onClick={prevSlide}>&#10094;</a>
          <a className="next" onClick={nextSlide}>&#10095;</a>
          <div className='caption-container'>
            <h1>Photo Gallery</h1>
          </div>
          <div className='grid'>
            {props.slides.map((slide, index) => {
              return (
                <img key={index}
                 onClick={() => currentSlide(index)}
                  className={`{"imagerow demo cursor ${index===current? ' active' : ''} "}`}
                  src={slide.image}></img>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )



}
