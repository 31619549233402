import React, { useState, useEffect } from 'react';
import { Button } from '../utils/Button';
import { Link } from 'react-router-dom';
import Scroll from 'react-scroll';

// var Link=Scroll.Link; // Use to only scroll
let scroll = Scroll.animateScroll;


export default function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener('resize', showButton);

  const toggleHome = () => {
    scroll.scrollToTop()
  }



  return (
    <>
      <nav className='navbar'>
        <div className='navbar-container'>
        <img className='logo' src='images/KriusLogo1.png' ></img>
          <Link to='/' className='navbar-logo' onClick={closeMobileMenu && toggleHome}>
            
            {/* <i className='fab fa-typo3' /> */}
          </Link>
          <div className='menu-icon' onClick={handleClick}>
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
          </div>


          <ul className={click ? 'nav-menu active' : 'nav-menu'}>

            <li className='nav-item'><Link
              className='nav-links' to="/" smooth="true" offset={-100}
              onClick={closeMobileMenu && toggleHome}
            >Home</Link>
            </li>

            <li className='nav-item'>
              <Link
                to='/mint'
                className='nav-links'
                onClick={closeMobileMenu}
                >Mint</Link>
            </li>

            <li className='nav-item'><Scroll.Link
              className='nav-links'
              to="photos"
              smooth="true"
              
              onClick={closeMobileMenu}
            >Photos</Scroll.Link>
            </li>

            <li className='nav-item'><Scroll.Link
              className='nav-links'
              to="roadmap"
              smooth="true"
              offset={-100}
              onClick={closeMobileMenu}
            >Roadmap</Scroll.Link>
            </li>

            <li className='nav-item'><Link
                to='/faq'
                className='nav-links'
                onClick={closeMobileMenu}
              >FAQ</Link>
            </li>

            <li>
              <Link
                to='/sign-up'
                className='nav-links-mobile'
                onClick={closeMobileMenu}
              >
                Sign Up
              </Link>
            </li>
          </ul>
          {button && <Button buttonStyle='btn--outline' linksTo='login'>Log In</Button>}
        </div>
      </nav>
    </>
  );
}

