export const roomData = [
  {
    title: 'Token Only',
    image: 'images/img-9.jpg',
    otherImage:'images/img-4.jpg',
    otherImage2:'images/img-3.jpg',
    text: 'Equity in the structure and land itself. With Dividends payable semi-annually.'
  },
  {
    title: 'Penthouse',
    image: 'images/img-6.jpg',
    otherImage:'images/img-1.jpg',
    otherImage2:'images/img-2.jpg',
    text: 'Luxurious stay in a modern penthouse, space for up to 16 guests, with 8 bedrooms, and 11 bathrooms, spaced over 2 floors and totalling 11,000 sqft. Includes a private pool, sauna, and gym'
  },
  {
    title: '2 Bedroom',
    image: 'images/img-5.jpg',
    otherImage:'images/img-9.jpg',
    otherImage2:'images/img-9.jpg',
    text: '1,800 sqft, 2 Bedroom 3 bathroom condominium unit, With space for up to 4 guests. Includes a full kitchen and dining area, washer and dryer units.'
  },
  {
    title: '3 Bedroom',
    image: 'images/img-7.jpg',
    otherImage:'images/img-9.jpg',
    otherImage2:'images/img-9.jpg',
    text: '2,000 sqft, 3 bedroom 4 bathroom condminium unit , with space for up to 6 guests. All units include a private terrace, Full kitchen and dining area. Washer and dryer are provided.'
  }
];
