import React, { useState } from "react";
import axios from "axios";
import "../components/css/login.css";


export default function Login(props) {
  const [uEmail, setEmail] = useState("");
  const [uPass, setPass] = useState("");

  const onChangeUserEmail = (e) => setEmail(e.target.value);
  const onChangeUserPassword = (e) => setPass(e.target.value);

  const handleSubmit = (evt) => {
    evt.preventDefault();
    //Debugging
    console.log(`Form submitted:`);
    console.log(`Email: ${uEmail}`)
    console.log(`Password: ${uPass}`)

    const userlogininfo = {
      email: uEmail,
      password: uPass
    }

    axios.post('http://localchost:4000/login', userlogininfo)
      .then(resp => {
        console.log(resp.data)
        console.log(resp.data[0].uEmail)
        console.log(resp.data[0].name)
        sessionStorage.setItem("Key_variable", 'USER')
        sessionStorage.setItem("userEmail", resp.data[0].uEmail)
        sessionStorage.setItem("userName", resp.data[0].name)
        props.history.push('/userafterlogin')

        setEmail("")
        setPass("")
      })

  }



  return (
    <div className="wrapper">
      <div className="form-wrapper">
        <h3> USER LOGIN </h3>
        <form style={{display:'flex', flexDirection:'column'}} onSubmit={handleSubmit}>
          <input className='login-input' type="email" value={uEmail}
            onChange={onChangeUserEmail}
            placeholder="Enter Email"
            required
          />
          <input className='login-input' type="password" value={uPass}
            onChange={onChangeUserPassword}
            placeholder="Enter Password"
            required
          />
          <input className='login-input btn--large' type="submit" value="   LOGIN   "  />
          
          <a href="/sign-up">Need to make an account ? </a>
        </form>
      </div>
    </div>


  )
}
