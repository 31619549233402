import React, { useState } from 'react';
// import { ScrollerData } from '../Data/ScrollerData';


function Scroller(props) {
  const [current, setCurrent] = useState(0);
  const length = props.slides.length;

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  if (!Array.isArray(props.slides) || props.slides.length <= 0) {
    return null;
  }

  
  return (
    <div className='scroller'>
      <section className='scroller-container' >

        {props.slides.map((slide, index) => {
          return (
            <>
              <div className='left-arrow fas fa-chevron-left' onClick={prevSlide} />
              <div className='right-arrow fas fa-chevron-right' onClick={nextSlide} />
              <div key={index} className={index === current ? 'slide active' : 'slide'}>

                {index === current && (
                  <img src={slide.image}  alt='travel' className='scroller-image' />

                )}
              </div>
            </>
          );
        })}
      </section>
    </div>
  );
  
};

export default Scroller;
