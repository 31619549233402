export const ScrollerData = [
  
  {
    image:
      'images/RCLAGUN_00390-1.jpg'
  },
  {
    image:
      'images/RCLAGUN_00606.jpg'
  },
  {
    image:
      'images/img-8.jpg'
  },
  {
    image:
      'images/img-6.jpg'
  }
];
