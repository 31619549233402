import React from 'react';
import { Button } from '../utils/Button';

function HeroSection() {
  return (
    <div className='hero-container'>
      {/* <img style={{position:'relative', zIndex:'1'}} src='/images/BG2.jpg'></img> */}
      {/* <video src='/videos/video-1.mp4' autoPlay loop muted id='hero-video' /> */}
      <h1>Mint our NFT</h1>
      <p>What are you waiting for?</p>
      <div className='hero-btns'>
        <Button
        
          className='btns'
          buttonStyle='btn--outline'
          buttonSize='btn--large'
          linksTo='mint'
        >
          Lets Mint
        </Button>
        {/* <Button
          className='btns'
          buttonStyle='btn--primary'
          buttonSize='btn--large'
          onClick={console.log('hey')}
        >
          WATCH TRAILER <i className='far fa-play-circle' />
        </Button> */}
      </div>
      
    </div>
  );
}

export default HeroSection;
