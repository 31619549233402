import React from 'react';

const items = [{
  title: "April 2022",
  contentText: 'First minting of Project, and allowing construction to begin',
  image: "images/img-1.jpg"
},
{
  title: "May 2022",
  contentText: 'Construction Completes, and reservation system opens',
  image: "images/img-2.jpg"
},
{
  title: "June 2022",
  contentTitle: "Dunkirk",
  contentText: 'Release of 2nd project which includes a winery',
  image: "images/img-3.jpg"
},
{
  title: "June 2022",
  contentTitle: "Dunkirk",
  contentText: 'Release of 3rd project, another hotel location is revealed and early access is granted to holders.',
  image: "images/img-3.jpg"
}
];

export default function RoadmapCard(props) {

  const flipped = props.flipped;
  const entry = items[props.entry];

  if (flipped) {
    return (
      <div className='Roadmap-map' >

        <div className='Roadmap-text'>
          <h1 style={{ marginTop: '20px' }}>{entry.title}</h1>
          <div>
            <p style={{ padding: '20px' }}>{items[props.entry].contentText}</p>
          </div>
        </div>
        <div className='Roadmap-image' >
          {/* <img alt="roadmap" style={{ objectFit: 'cover', width: '100%', height: '100%' }} src={entry.image}></img> */}
        </div>
      </div>
    )
  }
  else {
    return (
      <div className='Roadmap-boundary'>
        <div className='Roadmap-map-flipped' >


          <div className='Roadmap-image-flipped'>
            {/* <img alt="roadmap" style={{ objectFit: 'cover', width: '100%', height: '100%' }} src={entry.image}></img> */}
          </div>
          <div className='Roadmap-text-flipped'>
            <h1 style={{ marginTop: '20px' }}>{entry.title}</h1>
            <div>
              <p style={{ padding: '20px' }}>{items[props.entry].contentText}</p>
            </div>
          </div>
        </div>
      </div>
    )
  };
}