import React, { useState } from 'react';
import { roomData } from '../Data/roomData';
import '../css/main.css';


export default function ProjectCard(props) {
  const [Counter, setCounter] = useState(1);
  const Room = roomData[props.room];


  const counterInc = () => {
    setCounter(Counter <= 4 ? Counter + 1 : Counter);
  };
  const counterDec = () => {
    setCounter(Counter >= 1 ? Counter - 1 : Counter);
  };



  return (
    <div className='project-container'>
      <div className='project-text-container'>

        <div className='project-text'>
          <h1 style={{ marginBottom: '20px' }}>{Room.title}</h1>
          <p>{Room.text}</p>
        </div>
        <div className='project-mint'>
          <div className='project-mint-container'>
            <div className='fas-holder' onClick={counterDec}>
              <p className='fas fa-minus'  />
            </div>
            <h1> {Counter} </h1>
            <div className='fas-holder' onClick={counterInc}>
              <p className='fas fa-plus'  />
            </div>
          </div>
        </div>
        <button className='project-mint-button' onClick={() => alert("MINT FUNCTION GOES HERE")}>Mint</button>
      </div>

      <div className='project-photos-container'>
        <div className='project-photos-main'>
          <img alt="project" src={Room.image} className='project-photo'></img>
        </div>
        <div className='project-photos'>
          <img alt="project" src={Room.otherImage} className='project-photo'></img>
          <img alt="project" src={Room.otherImage2} className='project-photo'></img>

        </div>
      </div>
    </div>
  )
}