import React from 'react';
import '../App.css';
import HeroSection from '../components/majorComponents/HeroSection'
import MapDetails from '../components/majorComponents/MapDetails'
// import AboutUs from '../components/majorComponents/AboutUs'
import Scroller from '../components/majorComponents/Scoller';
import { ScrollerData } from '../components/Data/ScrollerData';
import Roadmap from '../components/majorComponents/Roadmap';
import Projects from '../components/majorComponents/Projects';
// import CardDisplay from '../components/majorComponents/CardDisplay';
import TextSection from '../components/majorComponents/TextSection';
import Gallery from '../components/majorComponents/SlideshowGallery';



// import Divider from '../components/utils/Divider';

function Home() {
  return (
    <div className='trial'>
      
      <HeroSection id="hero"/>
      
      <TextSection/>
      <div style={{height: '10px', width:'80vw', translate:'10vw', background:'#fff'}}></div>
      <Gallery slides={ScrollerData}/>
      {/* <Scroller slides={ScrollerData} /> */}
      <Projects/>
      
      {/* <div className='Divider'></div> */}
      {/* <CardDisplay id='photos'/> */}
      
      <Roadmap id='roadmap'/>
      {/* <MapDetails/> */}

      <div style={{height: '500px', background:'#f0a56e'}}>
        <div  className='image-container'>
          <img src='images/Wave-and-Beach-Transition-2-V2.jpg' />
        
      </div></div>
      <div className='iframe-container'>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1690.
          7798182282345!2d-116.88134363016279!3d32.05410899777054!2m3!1f0!2f0!3f0!3m2!
          1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x60e212c004ec7fa7!2zMzLCsDAzJzE0LjgiTiAx
          MTbCsDUyJzUwLjEiVw!5e0!3m2!1sen!2sus!4v1644888073906!5m2!1sen!2sus"
              border='0' frameBorder={0} allowFullScreen="" loading="lazy">
            </iframe>
          </div>
    </div>
  );
}

export default Home;