import axios from "axios";
import React, { useState } from "react";
import "../components/css/signup.css";



function Signup() {
  const [uEmail, setEmail] = useState("");
  const [uPass, setPass] = useState("");
  const [msg, setMessage] = useState("");

  const onChangeUserEmail = (e) => setEmail(e.target.value);
  const onChangeUserPassword = (e) => setPass(e.target.value);

  const handleSubmit = (evt) => {
    evt.preventDefault();
    //Debugging
    console.log(`Form submitted:`);
    console.log(`Email: ${uEmail}`);

    const userinfo = {
      email: uEmail,
      password: uPass
    };

    axios.post("http://localhost:4000/register", userinfo).then((res) => {
      console.log(res.data);
      setMessage("REGISTRATION SUCCESSFUL");
      console.log(msg);
    });

    setEmail("");
    setPass("");
  };

  return (
    <div className="signup-wrapper">
      <div className="signup-form-wrapper">
        <h3>CREATE ACCOUNT</h3>
        <form style={{display:'flex', flexDirection:'column'}} onSubmit={handleSubmit}>
          <input className='signup-input' type="email" value={uEmail}
            onChange={onChangeUserEmail}
            placeholder="Enter Email"
            required
          />
          <input className='signup-input' type="password" value={uPass}
            onChange={onChangeUserPassword}
            placeholder="Enter Password"
            required
          />
          <input className='signup-input btn--large' type="submit" value="Create Account" />
          <a href="login"> Already have an account ? </a>
        </form>
      </div>
    </div>

  );
}
export default Signup;
