import React from 'react';
import '../css/main.css';


export default function TextSection() {

  return (
    <div className='text'>
      <div className='text-container'>
        <ol className='text-container-grid'>
          <li>
            <h1>Genuine Ownership</h1>
            <p>100% of Ownership is given to holders! All holders are given a percentage based on the type of token held, and have a vote via a DAO.</p>
          </li>
          <li>
            <h1>Future Projects</h1>
            <p> In addition to having ownership of the property, holders also receive a stake in future projects.</p>
          </li>
          <li>
            <h1>Stay for Free</h1>
            <p>All holders are granted access to an all inclusive resort experience, drinks, stay, and transportation are provided entirely free of charge.</p>
          </li>
        </ol>

      </div>
    </div>
  )

}