// import React, { useState } from "react";


export default function faq() {
  return (
    <div className="faq">
      <div className="top-section">
        <h1>Frequently Asked Questions</h1>
      </div>
      <div className="accordion-list">
        <div className="accordion-item">
          <h3>Whats an NFT?</h3>
          <p>An NFT stands for non-fungible token, and in particular is any token which abides by the ERC-721 token standard. This establishes its non-fungability or the ability for it to be modified after its creation. Once an NFT has been minted it cannot be changed.</p>
        </div>
        <div className="accordion-item">
          <h3>Why tokenize real estate?</h3>
          <p>We believe that the future of investing, and contract law in general lies on the blockchain, an irrefutable ledger of transactions. By leveraging smart contracts we can automate the complex process of property management and abstract these concerns to tokens. If you own a token you own a portion of a property. These could be potentailly assigned as grant deeds for legitamate ownership of real property.</p>
        </div>
        <div className="accordion-item">
          <h3>What does this have to do with vacaction rentals?</h3>
          <p>As a solid foundation for </p>
        </div>
        <div className="accordion-item">
          <h3>Didn't people try this with regular tokens?</h3>
          <p>Yes they have, we believe that by linking ownership to an object which cannot change and is unique adds additional desirable properties. They are governmentally friendly to work with, by having uniqueness NFTs allow us to bind ownership to individuals, this is already the system in place for traditional real estate. This is preferable to the abstraction of ownership via tokens, which we believe should be exclusively used as currrency.
          </p>
          </div>
          <div className="accordion-item">
            <h3>So why NFTS?</h3>
            <p>
              NFTs allow for rapid transactions, rather than having to wait for ownership and payments to be tied up in escrow your transactions can
              be processed directly on chain.
            </p>
          
        </div>
      </div>
    </div>
  )
}

















// const qA = [
//   {
//     question: "How many team members can I invite?",
//     answer:
//       "You can invite up to 2 additional users on the Free plan. There is no limit on team members for the Premium plan.",
//   },
//   {
//     question: "What is the maximum file upload size?",
//     answer:
//       "No more than 2GB. All files in your account must fit your allotted storage space.",
//   },
//   {
//     question: "How do I reset my password?",
//     answer: `Click “Forgot password” from the login page or “Change password” from your profile page. A reset link will be emailed to you. Click “Forgot password” from the login page or “Change password” from your profile page. A reset link will be emailed to you.`,
//   },
//   {
//     question: "Can I cancel my subscription?",
//     answer: `Yes! Send us a message and we'll process your request no questions asked.`,
//   },
//   {
//     question: "Do you provide additional support?",
//     answer: `Chat and email support is available 24/7. Phone lines are open during normal business hours.`,
//   },
// ];



// const AccordionItem = ({ title, content }) => {
//   const [isActive, setIsActive] = useState(false);
//   return (
//     <div className="accordion-item">
//       <div className='accordion-title' onClick={() => setIsActive(!isActive)}>
//         <h1>{title}</h1>
//         <div>{isActive ? '-' : '+'}</div>
//       </div>
//       <div className="accordion-panel">
//         {isActive && <p className="accordion-text">{content}</p>}
//       </div>
//     </div>
//   );
// }

// function FAQ() {
//   return (
//     <div className="faq">
//       <h1> Frequently Asked Questions</h1>
//       <div className="accordion-list" >
//         {qA.map(({ question, answer }) => (
//           <AccordionItem title={question} content={answer} key={question} />
//         ))}
//       </div>
//     </div>
//   )
// }

// export default FAQ