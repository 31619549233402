import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';


import Footer from './components/majorComponents/Footer';
import Navbar from './components/majorComponents/Navbar';
// import Roadmap from './components/Roadmap';
import DBhandler from './components/utils/DatabaseHandler';
// import Scroller from './components/Scoller';
// import { ScrollerData } from './components/utils/ScrollerData';
import FAQ from './pages/FAQ';
import Home from './pages/Home';
import Login from './pages/Login';
import Mint from './pages/Mint';
import Signup from './pages/Signup';
import './App.css';


function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route exact path="/foot" element={<Footer />} />
          <Route exact path="/mint" element={<Mint />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/sign-up" element={<Signup />} />
          <Route exact path="/faq" element={<FAQ />} />
          {/* <Route exact path="/roadmap" element={<Roadmap/> } /> */}
          <Route exact path="/db" element={<DBhandler />} />
          {/* <Route exact path="/" component={Main} />
          <Route exact path="/title" component={Title} />
          <Route exact path="/logout" component={Logout} />
          <Route exact path="/userafterlogin" component={UserAfterLogin} />
          <Route exact path="/about" component={About} />
          <Route exact path="/dispprop" component={DispProp} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/add" component={Add} />
          <Route exact path="/properties" component={Properties} /> */}
          <Route exact path="/" element={<Home />} />
          <Route component={Error} />
        </Routes>
        <Footer />
      </Router>
    </>
  )
}

export default App;
