import React, { useState } from 'react';
import { Chrono } from 'react-chrono';
import RoadmapCard from '../utils/RoadmapCard';




export default function Roadmap(props) {
  const myId = props.id;
  const [width, setWidth] = useState(window.innerWidth);



  const updateWidth = () => {
    setWidth(window.innerWidth);
  }

  React.useEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  });


  if (width >= 1280) {
    const wrapper = document.getElementsByClassName('Roadmap-test');
    wrapper.outerHTML = wrapper.innerHTML;

    return (
      <>

        <div className='Roadmap' id={myId}>
          {/* <div className='stripe'>
            <h1>Roadmap</h1>
          </div> */}
          {/* <p>{width}</p> */}
          <Chrono
            className='Roadmap-test'
            activeItemIndex={-1}
            allowDunamicUpdate
            borderLessCards
            disableNavOnKey
            mode="VERTICAL"
            hideControls
            disableClickOnCircle
            theme={{
              cardBgColor: "",
              primary: "#fff",
              secondary:'#fff'
            }}
          >
            <RoadmapCard entry={0} flipped={true} />
            <RoadmapCard entry={1} flipped={true} />
            <RoadmapCard entry={2} flipped={true} />
            <RoadmapCard entry={3} flipped={true} />
          </Chrono>


        </div>
      </>
    );
  } else {
    return (
      <div className='Roadmap' id={myId}>
        {/* <div className='Roadmap-BG-line'></div> */}
        <h1><strong>Roadmap</strong></h1>
        {/* <p>{width}</p> */}
        <RoadmapCard entry={0} flipped={true} />
        <RoadmapCard entry={1} flipped={true} />
        <RoadmapCard entry={2} flipped={true} />
        <RoadmapCard entry={3} flipped={true} />



      </div>

    );
  }

}


