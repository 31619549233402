import React, {  useState } from 'react';
import '../css/main.css'; 


import ProjectCard from '../utils/ProjectCard';


//label functionality has been disabled check Cards.css

function Projects() {
  const [setProj, setProjState] = useState(0);



  return (
    <div className='project'>
      <div className='project-buttons '>
        <button
          className=' project-button'
          onClick={() => setProjState(2)}>2 Bedroom</button>
        <button
          className='project-button'
          onClick={() => setProjState(3)}>3 Bedroom</button>
        <button
          className='project-button'
          onClick={() => setProjState(1)}>Penthouse</button>
        <button
          className='project-button'
          onClick={() => setProjState(0)}>Token Only</button>
      </div>
        <ProjectCard room={setProj}/>
    </div>
  );
}

export default Projects;
